import * as React from "react";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import { Helmet } from "@elevio/kb-kit/lib/components/meta";
import { HomeLink } from "@elevio/kb-kit/lib/components/meta";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import Alert from "../components/Alert";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

const Page = () => {
  const { t } = useTranslation();
  const kbTitle = t("kb_title");

  return (
    <>
      <Helmet>
        <title>{kbTitle}</title>
      </Helmet>

      <Header />

      <div className="bg-color">
        <Alert />

        <div className="container index">
          <div className="row">
            <h1 className="articleTitle" style={{ textAlign: "center" }}>
              <Trans i18nKey="sdk.general.something_went_wrong">
                Sorry, something went wrong
              </Trans>
              .
            </h1>

            <div className="articleBody" style={{ textAlign: "center" }}>
              <p>
                <Trans i18nKey="visit_home_to_try_again">
                  Please visit to the <HomeLink>home page</HomeLink> and try
                  again.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Page;
