import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "@elevio/kb-kit/lib/App";
import "@elevio/kb-kit/lib/clientApi";
import config from "./config";
import "./css/index.css";
if (process.env.NODE_ENV === "development") {
  require("./css/custom.css");
}

const target = document.getElementById("kb-target");
if (!target) throw new Error("Cant find target div");

ReactDOM.render(<App config={config} />, target);

const ARTICLE_CONTAINER = "js-article-container";
const ARTICLE_CONTENT = "js-article-content";
const SIDEBAR_FIXED = "js-sidebar-fixed";
const TOP_PADDING = 20;

let scrollListener: EventListener;
function resetSidebar(elem: HTMLElement) {
  window.removeEventListener("scroll", scrollListener);
  elem.style.position = "relative";
  elem.style.top = "auto";
}

function sidebarFixed() {
  const sideBarElem = document.getElementById(SIDEBAR_FIXED);
  if (!sideBarElem) return;

  resetSidebar(sideBarElem);
  // sidebar is below article (CSS columns on mobile), do nothing
  if (document.documentElement.clientWidth < 700) return;
  // sidebar is bigger than viewport, do nothing (can't see all when fixed)
  if (sideBarElem.scrollHeight > document.documentElement.clientHeight) return;
  // sidebar is bigger than article, do nothing
  if (
    sideBarElem.scrollHeight >
    document.getElementById(ARTICLE_CONTENT)!.scrollHeight
  )
    return;
  const containerElem = document.getElementById(ARTICLE_CONTAINER)!;
  const bodyRect = document.body.getBoundingClientRect();
  const containerRect = containerElem.getBoundingClientRect();
  const elemRect = sideBarElem.getBoundingClientRect();
  const offset = elemRect.top - bodyRect.top;
  const MAX_OFFSET_TOP =
    containerElem.offsetTop + containerRect.height - elemRect.height - 50;

  // KB: this seems to break responiveness so I'm turning off, it was live in old KB though
  // sideBarElem.style.width = `${elemRect.width}px`;
  scrollListener = function listen() {
    const s = document.body.scrollTop;
    // scrolled past sidebar, fix it to top
    if (s > offset - TOP_PADDING && s < MAX_OFFSET_TOP) {
      sideBarElem.style.position = "fixed";
      sideBarElem.style.top = `${TOP_PADDING}px`;
      // scrolled to bottom, keep it above footer
    } else if (s >= MAX_OFFSET_TOP) {
      sideBarElem.style.position = "fixed";
      sideBarElem.style.top = `${MAX_OFFSET_TOP - s}px`;
      // nothing
    } else {
      sideBarElem.style.position = "relative";
      sideBarElem.style.top = "auto";
    }
  };
  window.addEventListener("scroll", scrollListener, false);
}

window.kb.on("article:load", () => {
  sidebarFixed();
  window.addEventListener("resize", sidebarFixed, false);
  window.addEventListener("orientationchange", sidebarFixed, false);
  let cachedHeight = document.body.scrollHeight;
  setInterval(() => {
    const h = document.body.scrollHeight;
    if (cachedHeight !== h) {
      cachedHeight = h;
      sidebarFixed();
    }
  }, 200);
});
