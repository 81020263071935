import * as React from "react";

type Props = {
  size: number;
  colour?: string;
  className?: string;
} & JSX.IntrinsicElements["svg"];

export const SVG: React.FC<Props> = ({
  size,
  children,
  className,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    className={className}
    {...props}
  >
    {children}
  </svg>
);

export const ThumbsDownIcon: React.FC = () => (
  <SVG size={14}>
    <path d="M3,6H0v8h3V6z M5.6,14h5c0.6,0,1-0.3,1.2-0.9l2-3.9C14,9,14,8.9,14,8.7V7.4l0,0l0-0.1c0-0.8-0.6-1.4-1.3-1.4 H9.4l0.6-4.2l0-0.2c0-0.3-0.1-0.6-0.3-0.7L9.1,0L4.7,5.6c-0.2,0.3-0.4,0.6-0.4,1v6C4.3,13.4,4.9,14,5.6,14" />
  </SVG>
);

export const ThumbsUpIcon: React.FC = () => (
  <SVG size={14}>
    <path d="M3,6H0v8h3V6z M5.6,14h5c0.6,0,1-0.3,1.2-0.9l2-3.9C14,9,14,8.9,14,8.7V7.4l0,0l0-0.1c0-0.8-0.6-1.4-1.3-1.4 H9.4l0.6-4.2l0-0.2c0-0.3-0.1-0.6-0.3-0.7L9.1,0L4.7,5.6c-0.2,0.3-0.4,0.6-0.4,1v6C4.3,13.4,4.9,14,5.6,14" />
  </SVG>
);

export const ClearIcon: React.FC = () => (
  <SVG size={60}>
    <path d="M35.7,30L58.8,6.8C59.6,6.1,60,5.1,60,4c0-2.2-1.8-4-4-4c-1.1,0-2.1,0.4-2.8,1.2L30,24.3 L6.8,1.2C6.1,0.4,5.1,0,4,0C1.8,0,0,1.8,0,4c0,1.1,0.4,2.1,1.2,2.8L24.3,30L1.2,53.2C0.4,53.9,0,54.9,0,56c0,2.2,1.8,4,4,4 c1.1,0,2.1-0.4,2.8-1.2L30,35.7l23.2,23.2c0.7,0.7,1.7,1.2,2.8,1.2c2.2,0,4-1.8,4-4c0-1.1-0.4-2.1-1.2-2.8L35.7,30z" />
  </SVG>
);

export const SearchIcon: React.FC = () => (
  <SVG size={60}>
    <path d="M58.8,53.2L48.7,43.1C52,38.6,54,33,54,27C54,12.1,41.9,0,27,0S0,12.1,0,27s12.1,27,27,27 c6,0,11.6-2,16.1-5.3l10.1,10.1c0.7,0.7,1.7,1.2,2.8,1.2c2.2,0,4-1.8,4-4C60,54.9,59.6,53.9,58.8,53.2z M8,27C8,16.5,16.5,8,27,8 s19,8.5,19,19s-8.5,19-19,19S8,37.5,8,27z" />
  </SVG>
);

export const EyeOffIcon: React.FC = () => (
  <SVG
    size={30}
    fill="none"
    stroke={"currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
    <line x1="1" y1="1" x2="23" y2="23" />
  </SVG>
);

export const EyeOnIcon: React.FC = () => (
  <SVG
    size={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke={"currentColor"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
    <circle cx="12" cy="12" r="3"></circle>
  </SVG>
);

export const ChevronUp: React.FC = () => (
  <SVG size={24}>
    <path d="M12.71 9.29l-4-4C8.53 5.11 8.28 5 8 5s-.53.11-.71.29l-4 4a1.003 1.003 0 001.42 1.42L8 7.41l3.29 3.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71z" />
  </SVG>
);

export const ChevronDown: React.FC = () => (
  <SVG size={24}>
    <path d="M12 5c-.28 0-.53.11-.71.29L8 8.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l4-4A1.003 1.003 0 0012 5z" />
  </SVG>
);
