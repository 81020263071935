import * as React from "react";
import { Helmet } from "@elevio/kb-kit/lib/components/meta";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import { useCategory } from "@elevio/kb-kit/lib/hooks/useCategory";
import { Header } from "../components/Header";
import Alert from "../components/Alert";
import { SideBar } from "../components/SideBar";
import { Footer } from "../components/Footer";

const Page = () => {
  const { t } = useTranslation();
  const category = useCategory();
  if (!category) return null;

  const kbTitle = t("kb_title");
  const title = `${category.title} - ${kbTitle}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Header />

      <div className="bg-color">
        <Alert />

        <div className="container category-index">
          <div className="row">
            <div className="col col-sm-12 col-md-6 col-lg-4">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Page;
