import * as React from "react";
import * as Category from "@elevio/kb-kit/lib/components/category";
import cx from "classnames";

type SubCategoryProps = {
  category: { id: string; title: string };
  isLast: boolean;
};
export const SubCategory: React.FC<SubCategoryProps> = ({
  category,
  isLast,
}) => (
  <div
    className={cx("subcategory-link", isLast && "last")}
    id={`category-link-${category.id}`}
  >
    <Category.Link
      className="page-text-color"
      categoryId={category.id}
      data-testid="subcategory-link"
    >
      <svg viewBox="49 529 5 5">
        <path d="M51,531 L49,531 L49,532 L51,532 L51,534 L52,534 L52,532 L54,532 L54,531 L52,531 L52,529 L51,529 L51,531 Z" />
      </svg>
      {category.title}
    </Category.Link>
  </div>
);
