import React, { useState } from "react";
import cx from "classnames";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { AccessLevel } from "@elevio/kb-kit/lib/types";
import { useArticleFull } from "@elevio/kb-kit/lib/hooks/useArticleFull";
import { EyeOffIcon, EyeOnIcon, ChevronUp, ChevronDown } from "./Icons";

type Props = {
  className?: string;
};

const AccessBanner: React.FC<Props> = ({ className }) => {
  const article = useArticleFull();
  const [showing, setShowing] = useState(false);

  if (!article) throw new Error("No article in AccessBanner");

  const { isInternal, smartGroups, access } = article.accessControl;
  const showRestrictions = smartGroups.length > 0;

  return (
    <div
      className={cx(
        "access-banner-wrapper",
        `access-${article.accessControl?.access?.toLowerCase()}`,
        article.accessControl?.isInternal
          ? "audience-internal"
          : "audience-external"
      )}
    >
      <div className="access-banner-container">
        <div className="access-message-container" data-testid="article-access">
          <span className="access-icon-container">
            {isInternal ? <EyeOffIcon /> : <EyeOnIcon />}
          </span>

          {isInternal && (
            <span className="access-banner-message">
              <strong>
                {access === AccessLevel.Restricted ? (
                  <Trans i18nKey="internal.restricted">
                    For internal use, with restrictions
                  </Trans>
                ) : (
                  <Trans i18nKey="internal.unrestricted">
                    For internal use
                  </Trans>
                )}
              </strong>
            </span>
          )}

          {!isInternal && (
            <span className="access-banner-message">
              <strong>
                {access === AccessLevel.Restricted ? (
                  <Trans i18nKey="external.restricted">
                    For public use, with restrictions
                  </Trans>
                ) : (
                  <Trans i18nKey="external.unrestricted">For public use</Trans>
                )}
              </strong>
            </span>
          )}
        </div>

        {showRestrictions && (
          <div
            className="access-banner-down-icon"
            onClick={() => setShowing(!showing)}
          >
            <div className="access-banner-chevron">
              {showing ? <ChevronUp /> : <ChevronDown />}
            </div>
          </div>
        )}
      </div>
      {showRestrictions && showing && (
        <ul className="access-banner-list">
          {smartGroups.map((s, i) => (
            <li key={i}>{s.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AccessBanner;
