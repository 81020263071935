import * as React from "react";
import { Helmet } from "@elevio/kb-kit/lib/components/meta";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import Alert from "../components/Alert";
import { LoginLink } from "@elevio/kb-kit/lib/components/auth";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

const Page = () => {
  const { t } = useTranslation();
  const kbTitle = t("kb_title");

  return (
    <>
      <Helmet>
        <title>{kbTitle}</title>
      </Helmet>

      <Header />

      <div className="bg-color">
        <Alert />
        <div className="container index">
          <div className="row">
            <h1 className="articleTitle" style={{ textAlign: "center" }}>
              <Trans i18nKey="sdk.kb.requires_login">
                This content requires login
              </Trans>
            </h1>

            <div className="articleBody" style={{ textAlign: "center" }}>
              <p>
                <LoginLink>
                  <strong>
                    <Trans i18nKey="sdk.kb.click_to_login">
                      Click here to login
                    </Trans>
                  </strong>
                </LoginLink>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Page;
