import React from "react";
import { useCategories } from "@elevio/kb-kit/lib/hooks/useCategories";
import { useCategory } from "@elevio/kb-kit/lib/hooks/useCategory";
import * as Category from "@elevio/kb-kit/lib/components/category";
import * as Article from "@elevio/kb-kit/lib/components/article";
import { useArticles } from "@elevio/kb-kit/lib/hooks/useArticles";
import { IArticleFragment } from "@elevio/kb-kit/lib/types";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import cx from "classnames";
import { SubCategory } from "./SubCategory";

type Props = {
  categoryId?: string;
  className?: string;
};

const CardSubCategoryList: React.FC<{ categoryId?: string }> = ({
  categoryId,
}) => {
  const { categories, totalCount } = useCategories({
    parentCategoryId: categoryId,
  });

  if (totalCount > 0) {
    return (
      <>
        {categories.map((sub, i) => (
          <SubCategory
            key={sub.id}
            category={sub}
            isLast={i === totalCount - 1}
          />
        ))}
      </>
    );
  }
  return null;
};

const CardArticleLinks: React.FC<{ articles: Array<IArticleFragment> | null }> =
  ({ articles }) => {
    const length = articles?.length || 0;
    return (
      <>
        {(articles || []).map((article, i) => {
          const { title } = article;
          return (
            <div
              className={cx("article-link", { last: i === length - 1 })}
              key={article.id}
            >
              <Article.Link
                articleId={article.id}
                className="link-color"
                data-testid="article-link"
                id={`article-link-${article.id}`}
              >
                {title}
              </Article.Link>
            </div>
          );
        })}
      </>
    );
  };

const CardMoreBadge: React.FC<{
  totalCount: number | null;
  numArticles: number;
}> = ({ totalCount, numArticles }) => {
  if (totalCount) {
    if (totalCount <= numArticles) {
      return null;
    }

    return (
      <div className="article-more">
        <Category.Link className="show-more">
          {totalCount - numArticles} <Trans i18nKey="sdk.more">More</Trans>
        </Category.Link>
      </div>
    );
  }
  return null;
};

const CardArticleList: React.FC<{
  articles: Array<IArticleFragment> | null;
  totalCount: number | null;
}> = ({ articles, totalCount }) => {
  return (
    <>
      <CardArticleLinks articles={articles} />
      <CardMoreBadge
        totalCount={totalCount}
        numArticles={articles?.length || 0}
      />
    </>
  );
};

const CardSummaryList: React.FC<{ categoryId?: string }> = ({ categoryId }) => {
  const { articles, totalCount } = useArticles({ categoryId, limit: 5 });

  return (
    <>
      <CardSubCategoryList categoryId={categoryId} />
      <CardArticleList articles={articles} totalCount={totalCount} />
    </>
  );
};

export const CategoryCard: React.FC<Props> = ({ categoryId }) => {
  const category = useCategory({ categoryId });
  if (!category) return null;
  const { title, id } = category;

  return (
    <>
      <div className="category-title" id={`category-link-${id}`}>
        <h4>
          <Category.Link
            className="page-text-color"
            data-testid="category-link"
          >
            {title}
          </Category.Link>
        </h4>
      </div>
      <CardSummaryList categoryId={id} />
    </>
  );
};
