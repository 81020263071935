import * as React from "react";
import cx from "classnames";
import { getSearchAsYouTypeEnabled } from "@elevio/kb-kit/lib/utils/globals";
import * as Search from "@elevio/kb-kit/lib/components/search";
import * as SearchResults from "@elevio/kb-kit/lib/components/searchResults";
import { useSearch } from "@elevio/kb-kit/lib/hooks/useSearch";
import { useSearchResults } from "@elevio/kb-kit/lib/hooks/useSearchResults";
import { useOnClickOutside } from "@elevio/kb-kit/lib/hooks/useOnClickOutside";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { ClearIcon, SearchIcon } from "./Icons";

const Dropdown: React.FC = ({ children }) => {
  return (
    <div className="dropdown">
      <div
        className="mainViewContainer"
        data-testid="dropdown-content"
        dir="auto"
      >
        {children}
      </div>
    </div>
  );
};

const NoResults: React.FC = ({ children }) => (
  <div className="noResults">{children}</div>
);

const Loading: React.FC = () => (
  <div className="mainLoadingView">
    <span className="loadingRoot" />
  </div>
);

const RightIcon: React.FC = () => {
  const { value } = useSearch();
  const { clearResults } = useSearchResults();
  const onClick = () => clearResults();
  return (
    <div className="rightIcon" onClick={onClick} data-testid="close-button">
      {value && (
        <div className="closeButtonContainer">
          <ClearIcon />
        </div>
      )}
    </div>
  );
};

const ArticleTitle: React.FC = () => {
  return (
    <SearchResults.ArticleLink
      className="articleTitleRoot"
      data-testid="article-link"
    >
      <span className="title">
        <div>
          <SearchResults.ArticleTitle />
        </div>
      </span>
    </SearchResults.ArticleLink>
  );
};

type SearchDropdownProps = {
  outerRef: React.RefObject<HTMLDivElement>;
};
const SearchDropdown: React.FC<SearchDropdownProps> = ({ outerRef }) => {
  const { onCloseDropdown, value } = useSearch();
  const { isFetching, searchTerm } = useSearchResults();
  // const { isFetching, results, searchTerm } = useSearchResults();
  useOnClickOutside(outerRef, onCloseDropdown);

  if (value.length === 0) return null;
  if (isFetching) {
    return (
      <Dropdown>
        <Loading />
      </Dropdown>
    );
  }

  if (value !== searchTerm) {
    return (
      <Dropdown>
        {getSearchAsYouTypeEnabled() ? (
          <Loading />
        ) : (
          <NoResults>
            <Trans i18nKey="sdk.trigger_search">Hit enter to search for:</Trans>{" "}
            <span>"{value}"</span>
          </NoResults>
        )}
      </Dropdown>
    );
  }

  return (
    <Dropdown>
      <SearchResults.LoopResults>
        <ArticleTitle />
      </SearchResults.LoopResults>
      <SearchResults.IsEmpty>
        <NoResults>
          <Trans i18nKey="sdk.sorry">Sorry.</Trans>{" "}
          <Trans i18nKey="sdk.no_results"> No results.</Trans>{" "}
          <span>"{value}"</span>
        </NoResults>
      </SearchResults.IsEmpty>
    </Dropdown>
  );
};

const SearchContainerContent: React.FC = () => {
  const { isOpen } = useSearch();
  const { t } = useTranslation();

  const searchInputContainer = cx("searchInputContainer", {
    hasResults: isOpen,
  });

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <span ref={ref} className="searchRoot">
      <div className={searchInputContainer} dir="auto">
        <span className="searchIconContainer">
          <SearchIcon />
        </span>
        <Search.Input
          placeholder={t("sdk.search", "Search for help")}
          className="searchInput"
          data-testid="search-input"
          type="text"
        />
        <RightIcon />
      </div>
      {isOpen && <SearchDropdown outerRef={ref} />}
    </span>
  );
};

export const SearchContainer = () => {
  return (
    <div id="js-search-container" className="search-attach">
      <SearchContainerContent />
    </div>
  );
};
