import * as React from "react";
import * as Categories from "@elevio/kb-kit/lib/components/categories";
import { Helmet } from "@elevio/kb-kit/lib/components/meta";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import { Header } from "../components/Header";
import Alert from "../components/Alert";
import { CategoryCard } from "../components/CategoryCard";
import { Footer } from "../components/Footer";

const ClearFix: React.FC<{ index: number }> | null = ({ index }) => {
  return (index + 1) % 3 === 0 ? <div className="clearfix" /> : null;
};

const Page = () => {
  const { t } = useTranslation();
  const kbTitle = t("kb_title");
  const kbTagline = t("kb_tagline");
  const title = kbTagline ? `${kbTitle} - ${kbTagline}` : `${kbTitle}`;

  return (
    <div>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}

      <Header />
      <div className="bg-color">
        <Alert />

        <div className="container index">
          <div className="row">
            <Categories.Loop>
              {({ index }) => (
                <>
                  <div className="col col-sml-12 col-md-6 col-lg-4">
                    <div className="category-section">
                      <CategoryCard />
                    </div>
                  </div>
                  <ClearFix index={index} />
                </>
              )}
            </Categories.Loop>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page;
