import * as React from "react";
import * as Meta from "@elevio/kb-kit/lib/components/meta";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import InnerHtml from "@elevio/kb-kit/lib/components/InnerHtml";
import { SearchContainer } from "./SearchContainer";
import { Login } from "./Auth";
import { getCompanyUrl } from "@elevio/kb-kit/lib/utils/globals";

export const Header = () => {
  const { t } = useTranslation();
  const companyUrl = getCompanyUrl();
  const companyUrlClean = companyUrl && companyUrl.replace(/(^\w+:|^)\/\//, "");
  const kbMeta = t("kb_meta");
  const customHeader = t("kb_header");

  return (
    <div className="header-wrap" data-testid="header">
      <Meta.Helmet>
        <meta property="og:site_name" content={t("kb_title")} />
        <meta property="og:type" content="article" />
      </Meta.Helmet>

      {kbMeta && (
        <Meta.Helmet>
          <meta name="description" content={kbMeta} />
          <meta property="og:description" content={kbMeta} />
        </Meta.Helmet>
      )}

      {customHeader && (
        <InnerHtml className="custom-kb_header" html={customHeader} />
      )}

      {!customHeader && (
        <div className="header header-bg-color header-text-color">
          <div className="container">
            <div className="row">
              <div className="col col-sm-12">
                {companyUrl && (
                  <a className="right site-link" href={companyUrl}>
                    <strong>{companyUrlClean}</strong>
                  </a>
                )}

                <Login className="right site-link" />

                <h1>
                  <Meta.HomeLink>
                    <Trans i18nKey="kb_title"></Trans>
                  </Meta.HomeLink>
                </h1>
                <h4>
                  <Trans i18nKey="kb_tagline"></Trans>
                </h4>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="search-container search-bg-color">
        <div className="container">
          <div className="row">
            <div className="col col-sm-12">
              <SearchContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
