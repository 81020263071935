import * as React from "react";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { HomeLink, Helmet } from "@elevio/kb-kit/lib/components/meta";
import Alert from "../components/Alert";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

const Page = () => {
  const { t } = useTranslation();
  const kbTitle = t("kb_title");

  return (
    <>
      <Helmet>
        <title>{kbTitle}</title>
      </Helmet>

      <Header />

      <div className="bg-color">
        <Alert />

        <div className="container index">
          <div className="row">
            <h1 className="articleTitle" style={{ textAlign: "center" }}></h1>

            <div className="articleBody" style={{ textAlign: "center" }}>
              <p>
                <Trans i18nKey="sdk.kb.content_unavailable">
                  The content may no longer be available, or you may have
                  mistyped the URL.
                </Trans>
              </p>
              <p>
                <HomeLink>
                  <Trans i18nKey="sdk.kb.unavailable_suggestion">
                    Try searching above, or visit the home page
                  </Trans>
                </HomeLink>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Page;
