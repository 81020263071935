import React, { useEffect } from "react";
import { ClearIcon } from "./Icons";

type Props = {
  className?: string;
  image?: HTMLImageElement;
  isShowing: boolean;
  hide: () => void;
};

const Lightbox: React.FC<Props> = ({ image, isShowing, hide }) => {
  useEffect(() => {
    const keyHandler = ({ key }: KeyboardEvent) => {
      if (key === "Escape") hide();
    };

    window.addEventListener("keydown", keyHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", keyHandler);
    };
  }, [hide]);

  if (!image || !isShowing) {
    return null;
  }

  return (
    <div className="lightbox-modal" onClick={hide}>
      <span className="lightbox-close pointer" onClick={hide}>
        <ClearIcon />
      </span>

      <div className="lightbox-modal-content">
        <img
          src={image.src}
          className="lightbox-image"
          alt={image.alt}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

export default Lightbox;
