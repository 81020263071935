import React from "react";
import cx from "classnames";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import {
  useArticleFeedback,
  IFeedbackForm,
} from "@elevio/kb-kit/lib/hooks/useArticleFeedback";
import { ThumbsDownIcon, ThumbsUpIcon } from "./Icons";

type FeedbackInitialProps = {
  className?: string;
  isLoading: boolean;
  onPositive: () => void;
  onNegative: () => void;
};

const FeedbackLoading = () => (
  <div className="loadingContainer">
    <span className="root"></span>
  </div>
);

export const FeedbackInitial: React.FC<FeedbackInitialProps> = ({
  className,
  onPositive,
  onNegative,
  isLoading,
}) => (
  <div id="article-feedback" className={className}>
    <div className="reactionRoot">
      {isLoading && <FeedbackLoading />}
      {!isLoading && (
        <>
          <div className="reactionLabel">
            <Trans i18nKey="sdk.feedback_helpful">Was this helpful?</Trans>
          </div>
          <div className="btnContainer">
            <button
              className="reaction down"
              onClick={onNegative}
              disabled={isLoading}
              data-testid="article-feedback-negative"
            >
              <ThumbsDownIcon />
            </button>
            <button
              className="reaction up"
              onClick={onPositive}
              disabled={isLoading}
              data-testid="article-feedback-positive"
            >
              <ThumbsUpIcon />
            </button>
          </div>
        </>
      )}
    </div>
  </div>
);

type FeedbackErrorProps = {
  className?: string;
};
const FeedbackError: React.FC<FeedbackErrorProps> = ({ className }) => (
  <div className={cx(className, "error")}>
    <Trans i18nKey="sdk.general.something_went_wrong">
      Sorry, something went wrong
    </Trans>
  </div>
);

type FeedbackSuccessProps = {
  className?: string;
};
export const FeedbackSuccess: React.FC<FeedbackSuccessProps> = () => (
  <div className="feedbackRoot">
    <div className="success" data-testid="article-feedback-success">
      <Trans i18nKey="sdk.thankyou">Thank you</Trans>
    </div>
  </div>
);

type ErrorLabelProps = {
  error: string | null;
};
const ErrorLabel: React.FC<ErrorLabelProps> = ({ error }) => {
  if (!error) return null;
  return <div className="error">{error}</div>;
};

type EmailInputProps = {
  error: string | null;
  disabled: boolean;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const EmailInput: React.FC<EmailInputProps> = ({ error, ...props }) => {
  const { t } = useTranslation();
  return (
    <>
      <input
        type="text"
        name="email"
        data-testid="article-feedback-email-input"
        placeholder={t("sdk.modules.support.email", "Email")}
        className="email"
        {...props}
      />
      <ErrorLabel error={error} />
    </>
  );
};

type MessageBoxProps = {
  error: string | null;
  disabled: boolean;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};
const MessageBox: React.FC<MessageBoxProps> = ({ value, error, ...rest }) => {
  const { t } = useTranslation();
  return (
    <>
      <textarea
        name="text"
        placeholder={t("sdk.feedback_type", "Type your feedback here")}
        data-gramm_editor="false"
        data-testid="article-feedback-feedback-input"
        value={value}
        {...rest}
      />
      <ErrorLabel error={error} />
    </>
  );
};

const SubmitButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  return (
    <button
      type="submit"
      className="submit"
      disabled={disabled}
      data-testid="article-feedback-submit-button"
    >
      <Trans i18nKey="sdk.submit">Submit</Trans>
    </button>
  );
};

type FeedbackFormProps = {
  className?: string;
  isEmailHidden: boolean;
  isSubmitting: boolean;
  showAnonymousWarning: boolean;
  messageError: string | null;
  emailError: string | null;
  values: IFeedbackForm;
  onFeedbackSkipped: () => void;
  handleMessageChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const FeedbackForm: React.FC<FeedbackFormProps> = ({
  isSubmitting,
  isEmailHidden,
  values,
  showAnonymousWarning,
  messageError,
  emailError,
  onFeedbackSkipped,
  handleEmailChange,
  handleMessageChange,
  onSubmit,
}) => {
  return (
    <div id="article-feedback" data-testid="article-feedback">
      <div className="feedbackRoot">
        <div className="text">
          <div className="textLabel">
            <Trans i18nKey="sdk.feedback_improve">
              How can it be improved?
            </Trans>
          </div>
          <form onSubmit={onSubmit} noValidate>
            <MessageBox
              disabled={isSubmitting}
              error={messageError}
              value={values.feedback}
              onChange={handleMessageChange}
            />

            {!isEmailHidden && (
              <EmailInput
                disabled={isSubmitting}
                error={emailError}
                value={values.email}
                onChange={handleEmailChange}
              />
            )}

            {showAnonymousWarning && (
              <div className="textLabel">
                <Trans i18nKey="sdk.submit_anon">
                  Are you sure you want to send this anonymously?
                </Trans>
              </div>
            )}

            <SubmitButton disabled={isSubmitting || !!messageError} />

            {!showAnonymousWarning && (
              <button
                disabled={isSubmitting}
                type="submit"
                className="skip"
                onClick={onFeedbackSkipped}
              >
                <Trans i18nKey="sdk.skip">Skip</Trans>
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

type ArticleFeedbackProps = {
  className?: string;
};
export const ArticleFeedback: React.FC<ArticleFeedbackProps> = ({
  className,
}) => {
  const {
    stage,
    isFeedbackEnabled,
    isLoading,
    values,
    isFeedbackFormSubmitting,
    emailFieldIsHidden,
    showAnonymousWarning,
    messageError,
    emailError,
    onSubmit,
    onNegativeReaction,
    onPositiveReaction,
    onFeedbackSkipped,
    handleMessageChange,
    handleEmailChange,
  } = useArticleFeedback();
  if (!isFeedbackEnabled) return null;
  if (stage === "error") return <FeedbackError className={className} />;
  if (stage === "positive" || stage === "submitted")
    return <FeedbackSuccess className={className} />;
  if (stage === "negative") {
    return (
      <FeedbackForm
        className={className}
        onFeedbackSkipped={onFeedbackSkipped}
        onSubmit={onSubmit}
        isSubmitting={isFeedbackFormSubmitting}
        isEmailHidden={emailFieldIsHidden}
        handleMessageChange={handleMessageChange}
        handleEmailChange={handleEmailChange}
        showAnonymousWarning={showAnonymousWarning}
        values={values}
        messageError={messageError}
        emailError={emailError}
      />
    );
  }
  return (
    <FeedbackInitial
      className={className}
      onPositive={onPositiveReaction}
      onNegative={onNegativeReaction}
      isLoading={isLoading}
    />
  );
};
