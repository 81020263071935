import * as React from "react";
import InnerHtml from "@elevio/kb-kit/lib/components/InnerHtml";
import {
  getCompanyUid,
  getCompanyName,
  getAgentLoginEnabled,
  getJSSnippetUrl,
} from "@elevio/kb-kit/lib/utils/globals";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import { AgentLogin } from "./Auth";

const ElevioBranding: React.FC = () => {
  if (window.whitelabelEnabled) {
    return null;
  }

  const companyUid = getCompanyUid();

  return (
    <div className="right">
      <a
        className="underline"
        rel="nofollow"
        href={`https://app.elev.io/referral?utm_source=customer&utm_medium=kb&utm_term=v2&utm_content=Knowledge%20Management&utm_campaign=${companyUid}`}
        target="_blank"
      >
        Knowledge Management
      </a>{" "}
      by
      <svg viewBox="0 0 247 70">
        <title>elevio</title>
        <g>
          <path d="M246.818259,43.5437116 C246.818259,30.3037974 236.08595,19.5714881 222.846036,19.5714881 C209.606979,19.5714881 198.874669,30.3037974 198.874669,43.5437116 C198.874669,56.7827688 209.606979,67.515078 222.846036,67.515078 C224.018423,67.515078 225.170241,67.4310912 226.297206,67.2682597 L213.531218,54.5031279 C210.430563,51.8652577 208.462873,47.9341628 208.462873,43.5437116 C208.462873,35.5992489 214.90243,29.1605489 222.846036,29.1605489 C230.789642,29.1605489 237.230055,35.5992489 237.230055,43.5437116 C237.230055,50.9311192 231.659504,57.0175889 224.489777,57.8326034 L232.255125,65.5979514 C232.258553,65.5970944 232.261981,65.5953804 232.265409,65.5936664 L236.792981,70.1220959 L236.792981,63.042354 C242.863168,58.6921822 246.818259,51.5798741 246.818259,43.5437116" />
          <polyline points="127.74 20.874 139.424 20.874 150.935 47.764 162.447 20.874 174.045 20.874 154.629 66.491 147.242 66.491 127.74 20.874" />
          <path d="M34.2768857 53.9486438C31.3827703 56.4348236 28.295828 57.5566468 23.8813806 57.5566468 17.3526948 57.5566468 12.1986497 53.7763852 11.0819684 46.9897401L47.850176 46.9897401C47.850176 45.9587597 47.9358768 45.444555 47.9358768 44.4127175 47.9358768 29.9807054 38.1419911 19.6717581 23.709979 19.6717581 9.02000756 19.6717581-.68434189e-14 31.1830874-.68434189e-14 43.6396965-.68434189e-14 57.0415851 9.36366771 67.6933356 23.8813806 67.6933356 31.573026 67.6933356 37.2318488 65.7145045 41.6951457 61.3669037L34.2768857 53.9486438zM23.8813806 29.3790859C29.9807054 29.3790859 34.8776482 32.6434288 36.4245474 37.9697326L11.5970302 37.9697326C13.8303926 32.2140679 18.2122737 29.3790859 23.8813806 29.3790859zM109.873548 53.9486438C106.979432 56.4348236 103.89249 57.5566468 99.4780426 57.5566468 92.9493568 57.5566468 87.7944546 53.7763852 86.6777734 46.9897401L123.445981 46.9897401C123.445981 45.9587597 123.531682 45.444555 123.531682 44.4127175 123.531682 29.9807054 113.737796 19.6717581 99.306641 19.6717581 84.6158125 19.6717581 75.596662 31.1830874 75.596662 43.6396965 75.596662 57.0415851 84.9594727 67.6933356 99.4780426 67.6933356 107.169688 67.6933356 112.828511 65.7145045 117.290951 61.3669037L109.873548 53.9486438zM99.4780426 29.3790859C105.577367 29.3790859 110.47431 32.6434288 112.020352 37.9697326L87.1928351 37.9697326C89.4270546 32.2140679 93.8080787 29.3790859 99.4780426 29.3790859z" />
          <polyline points="55.925 0 55.925 66.491 67.35 66.491 67.35 11.426 55.925 0" />
          <polyline points="179.903 20.874 179.628 20.874 179.628 66.491 191.054 66.491 191.054 32.025 179.903 20.874" />
        </g>
      </svg>
    </div>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  const pipe = getAgentLoginEnabled() && ` | `;
  const customFooter = t("kb_footer");
  const jsSnippetUrl = getJSSnippetUrl();
  return (
    <>
      {customFooter && (
        <InnerHtml className="custom-kb_footer" html={customFooter} />
      )}

      <div className="footer" data-testid="footer">
        <div className="container">
          <div className="row">
            <div className="col col-sm-12">
              © {new Date().getFullYear()} {getCompanyName()} | v2
              {pipe}
              <AgentLogin />
              <ElevioBranding />
            </div>
          </div>
        </div>
      </div>

      {jsSnippetUrl && (
        <InnerHtml html={`<script src=${jsSnippetUrl}></script>`} />
      )}
    </>
  );
};
