import * as React from "react";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import {
  AgentLoginLogout,
  LoginLogoutLink,
} from "@elevio/kb-kit/lib/components/auth";

interface StaticLoginProps {
  className?: string;
}

export const AgentLogin: React.FC<StaticLoginProps> = ({ className }) => {
  const { t } = useTranslation();
  const loginText = t("agentLogin");
  const logoutText = t("logout");
  return (
    <AgentLoginLogout
      className={className}
      loginChildren={loginText}
      logoutChildren={logoutText}
    />
  );
};

export const Login: React.FC<StaticLoginProps> = ({ className }) => {
  const { t } = useTranslation();
  const loginText = t("sdk.kb.login");
  const logoutText = t("logout");
  return (
    <LoginLogoutLink
      className={className}
      loginChildren={<strong>{loginText}</strong>}
      logoutChildren={<strong>{logoutText}</strong>}
    />
  );
};
