import React from "react";
import cx from "classnames";
import { useNotifications } from "@elevio/kb-kit/lib/hooks/useNotifications";

const Alert: React.FC = () => {
  const { notification } = useNotifications();
  if (!notification) return null;

  return (
    <div className="container">
      <div className="row">
        <div className="col col-sm-12">
          <div
            className={cx(
              "alert-box",
              `bg-${notification.type}`,
              notification.type
            )}
          >
            {notification.message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
