import * as React from "react";
import * as Category from "@elevio/kb-kit/lib/components/category";
import * as Articles from "@elevio/kb-kit/lib/components/articles";
import { Trans } from "@elevio/kb-kit/lib/components/trans";
import * as RelatedArticles from "@elevio/kb-kit/lib/components/relatedArticles";
import * as Article from "@elevio/kb-kit/lib/components/article";
import * as Meta from "@elevio/kb-kit/lib/components/meta";
import { useCategories } from "@elevio/kb-kit/lib/hooks/useCategories";
import { SubCategory } from "./SubCategory";

export const SideBar = () => {
  const { categories, totalCount } = useCategories();
  return (
    <div
      className="sidebar-fixed"
      id="js-sidebar-fixed"
      data-testid="sidebar"
      style={{ position: "relative", top: "auto" }}
    >
      <div className="back-link">
        <Meta.HomeLink>
          <svg
            fill="#000000"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </Meta.HomeLink>
      </div>

      <div className="category-title" dir="auto">
        <h4>
          <Category.Link
            className="page-text-color"
            data-testid="category-link"
          >
            <Category.Title />
          </Category.Link>
        </h4>
      </div>

      <div>
        {categories.map((sub, i) => (
          <SubCategory
            key={sub.id}
            category={sub}
            isLast={i === totalCount - 1}
          />
        ))}
      </div>

      <div className="articles-list" dir="auto">
        <Articles.Loop>
          <div className="article-link">
            <Article.IsActive>
              <Article.Link className="link-color active">
                <Article.Title />
              </Article.Link>
            </Article.IsActive>

            <Article.IsNotActive>
              <Article.Link className="link-color">
                <Article.Title />
              </Article.Link>
            </Article.IsNotActive>
          </div>
        </Articles.Loop>
      </div>

      <RelatedArticles.Count>
        {({ count }) => (
          <Meta.If test={!!count}>
            <div className="related-articles">
              <h4>
                <Trans i18nKey="sdk.related_articles">Suggested articles</Trans>
              </h4>
              <RelatedArticles.Loop>
                <div className="article-link">
                  <Article.Link className="link-color">
                    <Article.Title />
                  </Article.Link>
                </div>
              </RelatedArticles.Loop>
            </div>
          </Meta.If>
        )}
      </RelatedArticles.Count>
    </div>
  );
};
