import * as React from "react";
import * as Article from "@elevio/kb-kit/lib/components/article";
import { IArticleFull } from "@elevio/kb-kit/lib/types/index";
import { useArticleFull } from "@elevio/kb-kit/lib/hooks/useArticleFull";
import { useTranslation } from "@elevio/kb-kit/lib/hooks/useTranslation";
import { Helmet } from "@elevio/kb-kit/lib/components/meta";
import { stripTags } from "@elevio/kb-kit/lib/utils";
import { useLightbox } from "@elevio/kb-kit/lib/hooks/useLightbox";
import { Header } from "../components/Header";
import LightBox from "../components/LightBox";
import Alert from "../components/Alert";
import { ArticleFeedback } from "../components/ArticleFeedback";
import { SideBar } from "../components/SideBar";
import { Footer } from "../components/Footer";
import AccessBanner from "../components/AccessBanner";

export function articleMetaDescription(article: IArticleFull): string {
  let metaDescription = stripTags(article.body.substr(0, 200)) + "...";
  if (article.summary) {
    metaDescription = article.summary;
  }
  return metaDescription;
}

const Page = () => {
  const article = useArticleFull();
  const { t } = useTranslation();
  const { image, hide, onLightboxElementClick } = useLightbox();
  if (!article) return null; // this can't happen

  const kbMeta = articleMetaDescription(article);
  const kbTitle = t("kb_title");
  const title = `${article.title} - ${kbTitle}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />

      {/* Override the meta description to be article summary */}
      <Helmet>
        <>
          <meta name="description" content={kbMeta}></meta>
          <meta property="og:description" content={kbMeta} />
        </>
      </Helmet>

      <div className="bg-color">
        <Alert />
        <div id="js-article-container" className="container article-show">
          <div className="row">
            <div className="col col-sm-12 col-md-8 col-push-md-4">
              <div
                id="js-article-content"
                className="page-text-color article-content"
              >
                <AccessBanner />
                <Article.FeaturedImage className="articleFeaturedImage" />
                <h1 className="articleTitle" data-testid="article-title">
                  <Article.Title />
                </h1>

                <LightBox image={image} isShowing={!!image} hide={hide} />
                <Article.Body
                  className="articleBody"
                  noStyles
                  onClick={onLightboxElementClick}
                />
                <ArticleFeedback className="feedbackRoot" />
              </div>
            </div>

            <div className="col col-sm-12 col-md-4 col-pull-md-8">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Page;
